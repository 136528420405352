<template>
  <div class="overview-container">
    <div class="overview-head">
    </div>
    <h1 class="display-1 overview-title">Wartungsübersicht</h1>
      <div v-if="maintenances.length < 1" class="table-head symbols-container">
        <span>Es stehen keine Wartungsarbeiten an.</span><span class="material-symbols-outlined text-green head-symbol-small">check_circle</span>
      </div>
      <div class="grid2x1fr">
        <div v-for="maintenance in maintenances" :key="maintenance.maintenanceId" class="disturbance-container">
          <div class='table-default container-fluid'>
            <div :class="borderClass(maintenance)">
              <!--div v-if="maintenance.headline" class="table-head symbols-container">
                <span class="material-symbols-outlined text-light-red head-symbol-small">construction</span><span>{{ maintenance.headline }}</span>
              </div-->
              <div class="table-head symbols-container">
                <span class="material-symbols-outlined text-light-red head-symbol-small">construction</span><span>Wartungsarbeiten in {{ maintenance.region.join(', ').replace(/,(?=[^,]*$)/, ' und') }}</span>
              </div>
              <table class="table">
                <tbody>
                <tr v-if="maintenance.medium">
                  <td>Betroffener Dienst</td>
                  <td>{{ maintenance.medium.join(', ').replace(/,(?=[^,]*$)/, ' und') }}</td>
                </tr>
                <tr v-if="maintenance.region">
                  <td>Betroffene Region/en</td>
                  <td>{{ maintenance.region.join(', ').replace(/,(?=[^,]*$)/, ' und') }}</td>
                </tr>
                <tr v-if="maintenance.subregion">
                  <td class="sub-tablerow"><div class="border-left-second-level">Betroffene Stadtteile</div></td>
                  <td>{{ maintenance.subregion.join(', ').replace(/,(?=[^,]*$)/, ' und') }}</td>
                </tr>
                <tr v-if="maintenance.streets">
                  <td class="sub-tablerow"><div class="border-left-second-level">Betroffene Straßen</div></td>
                  <td>{{ maintenance.streets.join(', ').replace(/,(?=[^,]*$)/, ' und') }}</td>
                </tr>
                <tr v-if="maintenance.headline">
                  <td class="sub-tablerow"><div class="border-left-second-level">Überschrift</div></td>
                  <td>{{ maintenance.headline }}</td>
                </tr>
                <tr v-if="maintenance.begin">
                  <td>Beginn der Wartungsarbeiten</td>
                  <td>{{ formatDate(maintenance.begin) }}</td>
                </tr>
                <tr v-if="maintenance.end">
                  <td>Ende der Wartungsarbeiten</td>
                  <td>{{ formatDate(maintenance.end) }}</td>
                </tr>
                <tr v-if="maintenance.mail_sent_to">
                  <td>E-Mail versendet an</td>
                  <td>{{ maintenance.mail_sent_to.join(', ') }}</td>
                </tr>
                </tbody>
              </table>
              <div class='button-container'>
                <div class='grid4x1fr'>
                  <div class="image-container">
                    <img v-if="maintenance.medium.includes('Telefon')" class='medium-image' src="../../src/assets/img/phone.png" alt="#">
                    <img v-if="maintenance.medium.includes('Internet')" class='medium-image' src="../../src/assets/img/internet.png" alt="#">
                    <img v-if="maintenance.medium.includes('TV')" class='medium-image' src="../../src/assets/img/tv.png" alt="#">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import {get} from '../api.js';
export default {
  name: "DisturbanceOverview",
  data() {
    return {
      maintenances: []
    }
  },
  created() {
    this.getMaintenances();
  },
  methods: {
    formattedMedium(medium) {
      if (medium.includes('Gesamtausfall')) {
        return 'allen Diensten';
      } else {
        return medium.join(', ').replace(/,(?=[^,]*$)/, ' und');
      }
    },
    borderClass(disturbance) {
      if (disturbance.medium.includes('Internet')) {
        return 'issue-container border-green';
      } else if (disturbance.medium.includes('Telefon')) {
        return 'issue-container border-orange';
      } else {
        return 'issue-container border-blue';
      }
    },
    formatDate(date) {
      const dateObj = new Date(date);
      return `${String(dateObj.getDate()).padStart(2, '0')}.${String(dateObj.getMonth() + 1).padStart(2, '0')}.${dateObj.getFullYear()} ${String(dateObj.getHours()).padStart(2, '0')}:${String(dateObj.getMinutes()).padStart(2, '0')}`;
    },
    async getMaintenances() {
      try {
        var response = await get('/Maintenance/GetAllMaintenances');
        this.maintenances = response.data;
        this.formatMaintenance();
        console.log(response.message);
      } catch (error) {
        console.log(error);
      }
    },
    formatMaintenance() {
      this.maintenances.forEach(item => {
        item.medium = JSON.parse(item.medium);
        item.region = JSON.parse(item.region);
        item.subregion = JSON.parse(item.subregion);
        item.streets = JSON.parse(item.streets);
        item.mail_sent_to = JSON.parse(item.mail_sent_to);
      });
    }
  }
}
</script>

<style scoped>
@import "../assets/styles/overviews.css";
</style>